<template>
  <b-table
    :data="history"
    :striped="true"
    :hoverable="true"
    :mobile-cards="false"
    is-fullwidth
    style="width: 100%"
  >
    <b-table-column v-slot="props" field="id" label="Id" numeric sortable>
      {{ $utilities.padLeftWithNull(props.row.id) }}
    </b-table-column>

    <b-table-column v-slot="props" field="datum" label="Datum" sortable>
      {{ $utilities.formatUnixtimeToDmYHi(props.row.datum) }}
    </b-table-column>

    <b-table-column v-slot="props" field="descr" label="Vorgang">
      {{ props.row.descr }}
    </b-table-column>

    <b-table-column v-slot="props" field="bouser_name" label="durch" sortable>
      {{ props.row.bouser_name }}
    </b-table-column>
    <b-table-column v-slot="props" label="Details">
      <router-link :to="getDetailLink(props.row.module, props.row.ref_id)">{{
        getDetailLinkName(props.row.module, props.row.ref_id)
      }}</router-link>
    </b-table-column>
  </b-table>
</template>

<script>
import WineField from "../_helpers/winefield.vue"
import LabelsOffset from "../_helpers/labelsoffset.vue"
import AddToStock from "../consignments/addtostock.vue"
export default {
  name: "Sequencefield",
  components: {
    WineField,
    LabelsOffset,
    AddToStock
  },
  props: {
    history: { type: [Object, Array], required: true }
  },

  methods: {
    getDetailLink: function (module, ref_id) {
      if (module == "consignments") {
        return "/consignments/view/" + ref_id
      } else if (module == "invoices") {
        return "/invoices/view/" + ref_id
      }
      return ""
    },
    getDetailLinkName: function (module, ref_id) {
      if (module == "invoices" && ref_id != null) {
        return "Rechnung" 
      } else if (module == "consignments"  && ref_id != null) {
        return "Ankauf #" + this.$utilities.padLeftWithNull(ref_id)
      }
      return this.$utilities.padLeftWithNull(ref_id)
    }
  }
}
</script>
